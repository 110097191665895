import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = <h1 className="main-heading">{title}</h1>
  } else if (`${isRootPath}/tags/`) {
    header = <span className="header-link-home">{title}</span>
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      {/* <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </footer> */}
      <footer>
        © {new Date().getFullYear()} • {` `}
        <a href="https://dharmaraj.dev/portfolio">Portfolio</a> • {` `}
        <a href="/">Blog Home</a>
      </footer>
    </div>
  )
}

export default Layout
